<template>
  <main>
    <div class="form">
      <div class="done">
          <h2>Nous vous confirmons l'enregistrement de votre participation. </h2>
          <p>Nous traitons votre demande. Vous recevrez un mail de confirmation sous réserve de conformité de votre dossier.</p>
          <br />
          <p>Pour toute question écrivez-nous à l'adresse mail <a href="mailto:">contact-husqvarna@promo.dev</a></p>
          <br />
          <div width="100%" align="center">
          <a href="https://www.husqvarna.com/fr/" class="button">Retour</a>
          </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: 'done',
  methods : {
    gohome() {
      window.location.href='https://www.husqvarna.com/fr/'
    }
  }
}
</script>

<style lang="scss">
$blue: #273a60;
$orange : #eb4f15;
    .button,
    #back_button {
      display:block;
      cursor: pointer;
      padding: 10px 30px;
      text-align: center;
      background-color: $orange;
      border: 1px solid $orange;
      font-size: 1.5em ;
      color: white !important;
      text-transform: uppercase !important;
      font-family: "Trade Gothic";
      width: 250px;
      font-weight: bold;
   

      &:hover {
        background-color: $blue;
        border: 1px solid $blue;

        @media screen and (max-width: 768px) {
          background-color: $orange;
          
        }
      }

      @media screen and (max-width: 768px) {
        width: 48% !important;
        font-size: 17px ;
      }
    }

    a {
      text-decoration: none;
      color:$blue;
      font-weight: bold;
    }
</style>